$font-family-base: 'Nunito Sans', sans-serif;
$body-color: #312602;

$red: #E06055;
$blue: #120233;
$secondary: #D5D1D1;

$btn-font-weight: 600;
$btn-font-size: 1rem;
$btn-font-size-sm: 0.75rem;
$btn-line-height: 1.4;
$btn-border-radius: .4rem;
$btn-border-radius-sm: 0.6rem;
$btn-padding-y: 0.6rem;
$btn-padding-y-sm: 0.42rem;
$btn-padding-x-sm: 2rem;
$btn-disabled-opacity: 0.5;
$btn-focus-width: 0;
// button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

$input-font-size-lg: 0.9rem;
$input-font-size-sm: 0.8rem;
// $input-line-height-lg: 1.9;
$input-padding-y-lg: 0.6rem;
$input-padding-y-sm: 0.3rem;

$input-border-width: 2px;
$input-border-color: #d5d1d1;
// $input-border-radius-sm: 4px;
$input-focus-border-color: #120233;
$input-focus-box-shadow: none;
$input-placeholder-color: #757575;


$link-color: #5e97f6;
$link-hover-color: #5e97f6;
$link-hover-decoration: none;

$modal-content-border-radius: 0.6rem;
$modal-backdrop-bg: #314360;

$tooltip-bg: #757575;
$tooltip-color: #fff;
$tooltip-opacity: 0.9;