.alert-dialog {
  max-width: 350px;
  .modal-content {
    border: 0;
    min-height: 300px;
  }
  .modal-header {
    min-height: 50px;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 0.6rem 1rem;
    background: #e06055;
    color: #fff;
    justify-content: center;
    .mdn-icon {
      margin-right: 0.6rem;
    }
  }
  .modal-body {
    font-size: 0.88rem;
    flex: 0;
  }
  .modal-footer {
    border-top: 0;
    justify-content: center;
    flex: 1 1 auto;
    flex-direction: column;
    > * {
      margin: 0.5rem;
    }
  }

  &.alert-dialog-small {
    .modal-content {
      min-height: auto;
      text-align: center;
    }
  }
}
