.current-plan-btn {
  // background: #f8e0b5;
  background:  #FDE1AB;
  color: #1a1a1a;
  border-radius: 0;
  z-index: 1;
  height: inherit;
  position: relative;
  padding: 0 2rem;
  font-size: 1.25rem;
  white-space: nowrap;
  
  > span:first-child {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 110px;
    height: 110px;
    height: inherit;
    background: inherit;
    transform: translate(-68px, -28px) rotate(45deg);
    z-index: 1;
  }
  > span:last-child {
    position: relative;
    z-index: 2;
  }

  &:hover {
    // background: #f6bd5e;
    background: #ffbe24;
    color:white;
    fill: white;
  }
  

}

.current-starter-plan-btn {
  background:#FF739E;
  color: #1a1a1a;
  border-radius: 0;
  z-index: 1;
  height: inherit;
  position: relative;
  padding: 0 2rem;
  font-size: 1.25rem;
  white-space: nowrap;
  > span:first-child {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 110px;
    height: 110px;
    height: inherit;
    background: inherit;
    transform: translate(-68px, -28px) rotate(45deg);
    z-index: 1;
  }
  > span:last-child {
    position: relative;
    z-index: 2;
  }

  &:hover {
    background: #ff155e;
    color: white;
    fill: white;
  }
}

.current-pro-plan-btn {
  background:#CE66B0;
  color: #1a1a1a;
  border-radius: 0;
  z-index: 1;
  height: inherit;
  position: relative;
  padding: 0 2rem;
  font-size: 1.25rem;
  white-space: nowrap;
  > span:first-child {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 110px;
    height: 110px;
    height: inherit;
    background: inherit;
    transform: translate(-68px, -28px) rotate(45deg);
    z-index: 1;
  }
  > span:last-child {
    position: relative;
    z-index: 2;
  }

  &:hover {
    background: #ae007b;
    color: white;
    fill: white;
  }
}

.current-public-plan-btn {
  background:#AD217D;
  color: white;
  border-radius: 0;
  z-index: 1;
  height: inherit;
  position: relative;
  padding: 0 2rem;
  font-size: 1.25rem;
  white-space: nowrap;
  > span:first-child {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 110px;
    height: 110px;
    height: inherit;
    background: inherit;
    transform: translate(-68px, -28px) rotate(45deg);
    z-index: 1;
  }
  > span:last-child {
    position: relative;
    z-index: 2;
  }

  &:hover {
    background: #ae007b;
    color: white;
    fill: white;
  }
}

