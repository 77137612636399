.form-control {
  &::placeholder {
    opacity: 0.5 !important;
  }
}

.form-control-sm {
  border-width: 1px;
}

.form-group {
  position: relative;
  text-align: left;
  .form-label {
    font-size: 0.8rem;
    font-weight: 700;
    padding-left: 2px;
    text-transform: uppercase;
  }
  .form-text {
    padding-left: 2px;
    font-size: $input-font-size-lg;
  }
  &.is-invalid {
    .form-label {
      color: $red;
    }
    .input-group {
      .input-group-left-padding {
        background: $red !important;
      }
      .form-control {
        border-color: $red !important;
      }
    }
    .invalid-feedback {
      display: block;
      font-size: $input-font-size-lg;
      padding-left: 2px;
    }
  }

  &.form-group-sm {
    .form-label {
      margin-bottom: 0.2rem; 
      font-size: 0.7rem;
    }
    &.is-invalid {
      .invalid-feedback {
        font-size: $input-font-size-sm !important;
      }
    }
  }
}