$button-height: 80px;
$button-icon-width: 55px;
$button-icon-height: 58px;

.microsoft-button {
  background: black;
  border: none;
  outline: none;
  color: #fff;
  width: 100%;
  max-width: 390px;
  height: $button-icon-height + 10px;
  border-radius: 0.4rem;
  font-size: 1.5rem;
  text-align: left;
  padding:0;
  margin: 0;
  // background: url(./google.svg) no-repeat left center #4385f5;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  .icon {
    background: #000;
    width: $button-icon-width;
    height: $button-icon-height;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: $button-icon-height - 2px;
    margin: 5px 18px 5px 5px;
  }
}

@media (max-width: 575.98px) {
  .microsoft-button {
    font-size: 1.3rem;
    height: $button-icon-height / 1.5 + 10px;
    .icon {
      width: $button-icon-width / 1.5;
      height: $button-icon-height / 1.5;
      line-height: $button-icon-height / 1.5 - 2px;
      img {
        width: $button-icon-width / 1.5 - 10px;
      }
    }
  }
}
