$content-left-border: 3px;
$content-header-height: 75px;
$sidebar-animation-duration: 0.3s;
$sidebar-width: 312px;
$sidebar-short-width: 70px;
$window-padding: 11px;

#content {
  width: calc(100% - #{$sidebar-width} - #{$content-left-border});
  height: calc(100vh - #{$window-padding} * 2);
  position: absolute;
  top: 0;
  // left: calc(#{$sidebar-width} + #{$content-left-border});
  right: 0;
  margin: 0 0 0 $content-left-border;
  background: #fff;
  overflow-y: hidden;
  transition: width $sidebar-animation-duration linear;
  #content-title {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 1rem;
    height: $content-header-height;
    line-height: $content-header-height;
  }
  .header {
    height: $content-header-height;
    background: #f1efef;
    line-height: $content-header-height;
    display: flex;
    justify-content: flex-end;
    min-width: 700px;
    .title {
      height: inherit;
      line-height: inherit;
      margin: 0 0 0 1rem;
      white-space: nowrap;
    }
    .plan {
      width: 500px;
    }
  }
  .content-scroll {
    height: calc(100vh - #{$window-padding} * 2 - #{$content-header-height});
    overflow-y: auto;
    .content-container {
      position: relative;
      // min-height: calc(100vh - #{$window-padding} * 2 - #{$content-header-height});
      padding: 1rem;
    }
  }
  &.wider {
    width: calc(100% - #{$sidebar-short-width} - #{$content-left-border});
  }
}
