.form-control-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100px;
  height: 24px;
  line-height: 24px;
  background: #f5f5f5;
  border-radius: 18px;
  font-size: 0.88rem;
  text-align: center;
  color: #fff;
  &.is-invalid {
    background: #f5f5f5;
    border: 1px solid #e06055;
    color: #312602;
  }
}
