.icon-picker-label {
  margin-top: -6px;
  span {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 15px;
    padding: 9px;
    vertical-align: middle;
    &:first-child {
      padding: 0;
      width: auto;
      line-height: 37px;
    }
  }
}
.icon-picker-control {
  background: gray;
  border-radius: 9px;
  padding: 10px;
  position: relative;
  .icon-picker-control-search {
    padding-bottom: 15px;
    display: flex;
    svg {
      flex: 0 0 1.5rem;
    }
    input {
      margin: 0;
      border: 0;
      font-size: 0.7rem;
      outline: none;
      background: inherit;
      color: #fff;
      padding: 3px 0 3px 3px;
      vertical-align: middle;
      width: calc(100% - 1.5rem);
      &::placeholder {
        color: #fff;
      }
    }
  }
  .icon-picker-control-divider {
    display: block;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
  }
  .icon-picker-control-body {
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    &::-webkit-scrollbar-track {
      background-color: inherit;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: inherit;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
    > div {
      border: 1px solid transparent;
      width: 22px;
      height: 22px;
      text-align: center;
      margin: 0.1rem;
      cursor: pointer;
      line-height: 35px;
      outline: none;
      opacity: 0.5;
      padding: 1px;
      svg {
        display: block;
        path {
          fill: rgba(255, 255, 255, 1);
        }
      }
      &.selected {
        border-color: #fff;
        opacity: 1;
        cursor: default;
      }
    }
  }
}
