$btn-dotted-color: #757575;
$btn-dotted-border-color: #707070;
$btn-dotted-focus-border-color: #757575;


.btn {
  min-width: 125px;
  &.btn-long {
    width: 100%;
    max-width: 400px;
    font-size: 1.2rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  &:disabled, &.disabled {
    cursor: default;
  }
}
.btn-secondary {
  color: #757575;
}

.btn-form {
  width: 100%;
    max-width: 400px;
    font-size: 1.2rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    background-color: #F1EFEF;
    color: #312602;
    &:hover {
      color: w#312602;
      box-shadow: $btn-box-shadow, 3px 3px 6px 0px rgba(mix(color-yiq($btn-dotted-color), $btn-dotted-color, 15%), .5);
    }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    &:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled):active:hover, :not(:disabled):not(.disabled):active {
      box-shadow: $btn-box-shadow, 3px 3px 6px 0px rgba(mix(color-yiq($value), $value, 15%), .5) !important;
      background: $value;
      background-color: $value;
      color: color-yiq($value) !important;
      border-color: transparent !important;
    }
  }
  .btn-secondary {
    color: #757575;
    &:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled):active:hover {
      color: #757575 !important;
    }
  }
  .btn-outline-#{$color} {
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled).hover {
      background: rgba($value, 0.5);
      background-color: rgba($value, 0.5);
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background: $value;
      background-color: $value;
    }
  }
}

.btn-dotted {
  background: #fff;
  color: $btn-dotted-color;
  border: 1px dashed $btn-dotted-border-color;
  outline: none;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled).hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled).focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background: #F1EFEF;
    color: $btn-dotted-color;
    border: 1px solid $btn-dotted-focus-border-color;
    box-shadow: none;
    &:hover {
      box-shadow: $btn-box-shadow, 3px 3px 6px 0px rgba(mix(color-yiq($btn-dotted-color), $btn-dotted-color, 15%), .5);
    }
    svg {
      &.svg-default {
        display: none
      }
      &.svg-active {
        display: inline;
      }
    }
  }
  svg {
    margin: -2px 5px 0 0;
    width: 1rem !important;
    height: 1rem !important;
    > path {
      fill: $btn-dotted-color;
    }
    &.svg-active {
      display: none
    }
  }
}


.btn-clear {
  margin: 0;
  padding: 0;
  border: 0;
  box-shadow: none;
  width: auto;
  min-width: auto;
  outline: none;
  &:hover, &:active, &:focus {
    outline: none;
    box-shadow: none;
  }
}