.add-block-dialog-window {
  min-width: 900px;
  .modal-content {
    .modal-header {
      padding: 0.2rem 1rem;
      border-bottom: 0;
      button {
        color: #fff;
        font-weight: 400;
        font-size: 1rem;
        margin: 0 0.5rem 0 0;
      }
    }
    .add-block-dialog-content {
      min-height: 400px;
    }
  }
}

.add-block-dialog {
  max-width: 80%;
  max-height: 80%;
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    &-data {
      padding-right: 2rem;
    }
    &-colors {
      width: 335px;
      flex: 0 0 335px;
      padding-right: 2rem;
    }
    &-icons {
      min-width: 130px;
      flex: 0 0 15%;
      position: relative;
      .form-group-sm {
        height: 100%;
        .icon-picker-control {
          position: absolute;
          width: 100%;
          height: calc(100% - 45px);
        }
      }
    }
  }
}
