.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  &.loader-fixed {
    position: fixed;
  }
  &.loader-static {
    position: relative;
    height: auto;
    min-height: 3rem;
  }
  .loader-backdrop {
    background: #314360;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
  .loader-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
