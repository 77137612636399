.color-picker-control {
  display: flex;
  flex-wrap: wrap;
  margin: -0.3rem;
  > div {
    width: 35px;
    height: 35px;
    text-align: center;
    margin: 0.3rem;
    border-radius: 15px;
    cursor: pointer;
    line-height: 35px;
    outline: none;
    &.selected {
      cursor: default;
    }
  }
}
