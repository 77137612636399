.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrapper {
    display: flex;
    align-items: center;
    margin: 10px;
    font-size: 20px;
}

.innerWrapper {
    margin-right: 10px;
}

.wrapper1 {
    display: flex;
    align-items: center;
}

.wrapper2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    outline: 0px;
    margin-bottom: 6px;
}

.text {
    font-size: 12px;
    color: #312602;
    margin-bottom: 5px;
}