$input-group-left-padding-width: 9px;
$input-group-icon-width: 15px;
$input-group-icon-button-width: 2.5rem;

.input-group {
  .input-group-prepend {
    &.input-group-left-padding {
      width: 9px;
      margin-right: 0;
      background: $input-focus-border-color;
      @include border-left-radius($input-border-radius);
    }
  }
  .input-group-left-icon {
    position: absolute;
    width: $input-group-icon-width;
    top: 50%;
    left: calc(#{$input-padding-x-lg} + #{$input-group-left-padding-width});
    z-index: 4;
    transform: translateY(-50%);
    + .form-control {
      padding-left: calc(#{$input-padding-x-lg} * 2 + #{$input-group-icon-width});
    }
  }
  .form-control {
    border-left: 0px;
    color: #312602;
    &::placeholder {
      opacity: 0.5;
    }
  }
  &:hover {
    .form-control {
      border-color: #757575;
      &:focus {
        border-color: $input-focus-border-color;
      }
    }
  }

  .left-icon, .right-icon {
    position: absolute;
    width: $input-group-icon-button-width;
    top: $input-border-width;
    bottom: $input-border-width;
    left: $input-group-left-padding-width;
    z-index: 4;
    pointer-events: none;
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: block;
    outline: none;
    svg {
      > path {
        fill: rgba($input-placeholder-color, 0.5);
      }
    }
    &.left-icon svg {
      width: 1rem !important;
      height: 1rem !important;
    }
    &.as-button {
      pointer-events: auto;
    }
  }

  .right-icon {
    left: auto;
    right: $input-border-width;
  }

  &.with-left-icon {
    .form-control {
      padding-left: $input-group-icon-button-width;
    }
  }
  &.with-right-icon {
    .form-control {
      padding-right: $input-group-icon-button-width;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }
}