$sidebar-bottom-block-height: 80px;
$sidebar-header-height: 75px;
$sidebar-width: 312px;
$sidebar-short-width: 70px;
$window-padding: 11px;

$sidebar-animation-duration: 0.3s;
$sidebar-animation-function: linear;

@mixin animation($properties...) {
  transition-property: $properties;
  transition-duration: $sidebar-animation-duration;
  transition-timing-function: $sidebar-animation-function;
}

#sidebar {
  width: 312px;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - #{$window-padding} * 2);
  z-index: 999;
  background: #fff;
  padding-bottom: $sidebar-bottom-block-height;
  @include animation(width);
  button.burger {
    position: absolute;
    text-align: right;
    width: 20px;
    img {
      width: 8px;
      height: 20px;
      &.move {
        margin-right: 1px;
        @include animation(margin-right);
      }
    }
    &:hover {
      opacity: 0.5;
      img.move {
        margin-right: 3px;
      }
    }
  }
  .header {
    position: absolute;
    width: 100%;
    height: $sidebar-header-height;
    button {
      position: absolute;
      top: 1.6rem;
      @include animation(top, left);
      @for $i from 1 to 5 {
        &:nth-child(#{$i}) {
          left: calc((#{$i} - 1) * 3rem + 1.1rem);
        }
      }
    }
    .burger {
      right: 0.5rem;
      @include animation(top, right, transform);
    }
  }
  .content {
    width: $sidebar-width;
    height: calc(
      100vh - #{$window-padding} * 2 - #{$sidebar-bottom-block-height} - #{$sidebar-header-height}
    );
    overflow-y: hidden;
    margin-top: $sidebar-header-height;
    @include animation(opacity);
    transition-delay: $sidebar-animation-duration;
    > div {
      overflow-y: auto;
      height: inherit;
      padding: 0 0.9rem 0 1.1rem;
    }
    .menu {
      margin-left: -0.5rem;
      a {
        display: block;
        margin-bottom: 0.5rem;
        button {
          width: 100%;
          padding: 0.3rem 0.5rem;
          font-weight: 400;
          font-size: 0.9rem;
          text-align: left;
          color: #312602;
          border-radius: 0.32rem;
          &:hover {
            font-weight: 700;
            background: #f1efef;
          }
        }
      }
    }
  }
  .bottom-block {
    height: $sidebar-bottom-block-height;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1.3rem 0 1.3rem 1.1rem;
    .logo {
      width: 200px;
      float: left;
      overflow: hidden;
      @include animation(width, margin);
      img {
        width: 200px;
      }
    }
    button {
      right: 0.5rem;
      bottom: 25px;
      @include animation(bottom, right, transform);
    }
  }
  .btn-clear.with-icon {
    svg {
      path {
        fill: #312602;
      }
    }
    &:hover {
      svg {
        path {
          fill: rgba(#312602, 0.5);
        }
      }
    }
  }

  &.short {
    width: $sidebar-short-width;
    overflow: hidden;
    button.burger {
      transform: rotate(180deg);
    }
    .header {
      button {
        @for $i from 1 to 5 {
          &:nth-child(#{$i}) {
            top: calc((#{$i} - 1) * 4rem + 1.6rem);
            left: 1.5rem;
          }
        }
      }
      .burger {
        top: 17rem;
        right: 1.4rem;
      }
    }
    .content {
      opacity: 0;
      pointer-events: none;
      transition-delay: 0s !important;
    }
    .bottom-block {
      .logo {
        width: 45px;
        margin-left: -3px;
      }
      .burger {
        bottom: 70px;
        right: 1.4rem;
      }
    }
  }
}
