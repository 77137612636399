@import '../../../../assets/variables';
$expand-arrow: './expand_select_arrow.svg';
$input-group-right-button-width: 1.125rem;
$placeholder-color: #bcbcbc;

.input-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:invalid {
    color: $placeholder-color;
  }

  padding-right: $input-group-right-button-width + 2rem !important;

  option {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: $placeholder-color;
    &.presented-option {
      color: #312602;
    }
  }
}

.input-select-arrow {
  select {
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;

    ~ span {
      z-index: 4;
      position: absolute;
      pointer-events: none;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      transition: 0.4s;
      height: $input-group-right-button-width;
      width: $input-group-right-button-width;
      svg {
        > path {
          fill: $placeholder-color;
        }
        vertical-align: -0.125rem;
      }
    }

    &:hover {
      ~ span {
        box-shadow: inset 0px $input-group-right-button-width 0px $input-group-right-button-width
        #AE007B;
        svg {
          > path {
            fill: #fff;
          }
        }
      }
    }
  }
}
