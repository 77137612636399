@import '../../../../assets/variables';
$input-group-right-button-width: 1.125rem;
$placeholder-color: #bcbcbc;

.input-group.input-phone-arrow {
  flex-wrap: unset !important;
  align-items: unset !important;
  .form-control {
    flex: unset !important;
    &.input-phone-focus {
      border-color: #0c4ebb !important;
    }
  }
  .input-group-left-icon-phone {
    width: 24px !important;
    left: calc(7rem + 20px);
    pointer-events: none;
  }

  input {
    border-left-width: 1px !important;
    width: calc(100% - 7rem);
    padding-left: 2.5rem !important;
  }

  select {
    flex: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-right-width: 1px !important;
    width: 7rem;

    &:invalid {
      color: $placeholder-color;
    }

    option {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      color: $placeholder-color;
      &.presented-option {
        color: #312602;
      }
    }

    ~ span {
      z-index: 4;
      position: absolute;
      pointer-events: none;
      left: calc(7rem - 20px);
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      transition: 0.4s;
      height: $input-group-right-button-width;
      width: $input-group-right-button-width;
      svg {
        > path {
          fill: $placeholder-color;
        }
        vertical-align: -0.125rem;
      }
    }

    &:hover {
      ~ span {
        box-shadow: inset 0px $input-group-right-button-width 0px $input-group-right-button-width
          #86a3d0;
        svg {
          > path {
            fill: #fff;
          }
        }
      }
    }

    &.input-phone-right {
      border-color: #0c4ebb !important;
    }
  }
}
