.form-card {
  background: #fff;
  border: 1px solid #757575;
  padding: 2.2rem 3.7rem;
  // margin: 2.8rem 0;
  position: relative;
  text-align: center;
  .form-card-title {
    position: absolute;
    top: 0;
    left: 1.3rem;
    display: block;
    font-size: 0.88rem;
    text-transform: uppercase;
    background: inherit;
    margin: 0;
    line-height: 1.4;
    padding: 0 1rem;
    transform: translateY(-50%);
    color: #1a1a1a;
  }
  .form-card-subtitle {
    position: relative;
    &::before {
      display: block;
      content: ' ';
      border-top: 1px solid #757575;
      position: absolute;
      // width: 100%;
      top: 50%;
      left: 8%;
      right: 8%;
    }
    span {
      background: #fff;
      position: relative;
      padding: 0 0.6rem;
      text-transform: uppercase;
    }
  }
  .form-card-description {
    font-size: 0.88rem;
  }
}

@media (max-width: 575.98px) {
  .form-card {
    padding: 1.5rem;
  }
}
