$zone-block-color: #757575;
$zone-block-width: 200px;
$zone-block-height: 200px;
$zone-block-background: #0d47a1;
$zone-block-border-size: 2px;
$zone-block-border: 2px solid #707070;
$zone-block-empty-border: 2px dashed #f1efef;
$zone-block-empty-background: #f1efef;
$zone-block-border-radius: 0.6rem;
$zone-block-padding: 1rem;

$zone-block-content-width: 125px;
$zone-block-content-height: 125px;

.zone-blocks {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  // border: 1px solid coral
}

.zone-block-item {
  width: $zone-block-width;
  // border: 1px solid blue;
  height: $zone-block-height;
  position: relative;
  color: $zone-block-color;

  .zone-block-item-placeholder {
    width: $zone-block-content-width;
    height: $zone-block-content-height;
    margin: $zone-block-padding;
    border: $zone-block-border;
    border-color: transparent;
    overflow: visible;
    border-radius: 0.3rem;
    background: transparent;
    text-align: center;
    position: relative;
    z-index: 1;
    outline: none;
    .icon {
      width: calc(#{$zone-block-content-width} - 2rem);
      height: 4rem;
      position: absolute;
      left: calc(1rem - #{$zone-block-border-size});
      top: calc((#{$zone-block-content-height} - 4.5rem) / 2);
      svg {
        max-width: calc(#{$zone-block-content-width} - 2rem);
        height: inherit;
      }
    }
    .title {
      position: absolute;
      left: 0;
      top: calc(#{$zone-block-content-height} + 0.2rem); // 2-28-20222 changed from .3rem to .2rem so g's dont' get cut off on homepage
      font-size: 0.88rem;
      text-align: left;
      // white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 40px;
      line-height: 0.9rem;
      width: calc(#{$zone-block-content-width} + 1rem);
    }
  }

  .zone-block-item-content {
    width: $zone-block-width;
    height: $zone-block-height;
    background: $zone-block-empty-background;
    border: $zone-block-border;
    border-radius: $zone-block-border-radius;
    padding: $zone-block-padding;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    text-align: center;
    z-index: 2;

    .background {
      position: absolute;
      z-index: 1;
      width: $zone-block-content-width;
      height: $zone-block-content-height;
      top: calc(#{$zone-block-padding} - 2px);
      left: calc(#{$zone-block-padding} - 2px);
      background: $zone-block-background;
      border-radius: 0.3rem;
      &:hover {
        box-shadow: 0 3px 6px #746666;
      }
    }

    .icon {
      pointer-events: none;
      position: absolute;
      left: calc(#{$zone-block-padding} + 1.6rem);
      top: 3.2rem;
      z-index: 2;

      width: calc(#{$zone-block-content-width} - 2rem);
      height: 4rem;
      left: calc(#{$zone-block-padding} + 1rem - #{$zone-block-border-size});
      top: calc(#{$zone-block-padding} + (#{$zone-block-content-height} - 4.5rem) / 2);
      svg {
        max-width: calc(#{$zone-block-content-width} - 2rem);
        height: inherit;
      }
    }
    .title {
      position: absolute;
      left: $zone-block-padding;
      top: calc(#{$zone-block-content-height} + #{$zone-block-padding} + 0.3rem);
      font-size: 0.88rem;
      font-weight: 700;
      z-index: 2;
      text-align: left;
      // white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 40px;
      line-height: 0.9rem;
      width: calc(#{$zone-block-content-width} + 1rem);
    }

    .actions {
      position: absolute;
      top: 0;
      right: 0;
      width: 1.9rem;
      height: 100%;
      padding-top: 0.5rem;
      button {
        &.arrow-left {
          margin-left: -1px;
        }
        &.arrow-right {
          margin-left: 1px;
        }
        &.delete {
          position: absolute;
          left: 0.35rem;
          bottom: 0.6rem;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      &.only-left {
        button.arrow-right {
          display: none;
        }
      }
      &.only-right {
        button.arrow-left {
          display: none;
        }
      }
    }

    &:hover {
      display: block !important;
    }
  }

  &.no-interactive {
    pointer-events: none;
  }

  &:not(.empty) {
    .zone-block-item-placeholder {
      border-color: transparent;
      background: $zone-block-background;
      &:hover {
        overflow: visible;
        + .zone-block-item-content {
          display: block;
        }
      }
    }
  }

  &.empty .zone-block-item-placeholder {
    border: $zone-block-empty-border;
    opacity: 0.5;
    font-weight: 700;
    font-size: 1.25rem;
    padding-top: 1.6rem;
    cursor: pointer;
    .icons {
      svg {
        display: inline-block;
      }
      svg:last-child {
        display: none;
      }
    }
    &:hover {
      background: $zone-block-empty-background;
      opacity: 1;
      box-shadow: 0 3px 6px #746666;
      .icons {
        svg:first-child {
          display: none;
        }
        svg:last-child {
          display: inline-block;
        }
      }
    }
  }
}

.tooltip.tooltip-block-action {
  pointer-events: none;
}
