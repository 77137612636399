.container {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.wrapper1 {
    display: flex;
    height: 42px;
    align-items: center;
    color: white;
}

.wrapper2 {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.text {
    color: #486FB3;
    margin-left: 12px;
}

.text2 {
   font-size: 13px;
    margin-left: 25px;
}


.innerWrapper {
    margin-left: 20px;
    margin-top: 10px;
}

.iconwrapper {
    margin-right: 5px;
    margin-left: 10px;
}

.container2 {
    display: flex;
    margin-left: 30px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #0D47A1;
}

.boxborder {
    border-color: #aaa;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    margin-left: 30px;
    margin-right: 20px;
}

.innerBoxBorder {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.showIcons {
    overflow: auto;
    margin: 5px;
    width: 556px;
    height: 140px;
}

.GBInput {
    width: 7px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}