$sidebar-animation-duration: 0.2s;
$sidebar-animation-function: linear;

@mixin animation($properties...) {
  transition-property: $properties;
  transition-duration: $sidebar-animation-duration;
  transition-timing-function: $sidebar-animation-function;
}

.zone-add {
  button {
    border: 0;
    color: #bdbdbd;
    text-align: center;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0.3rem 0.5rem;
    border-radius: 0.32rem;
    text-align: left;
    padding-left: 0.9rem;
    position: relative;
    svg {
      margin-top: -2px;
    }
    span {
      background: #fff;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.8rem;
      position: absolute;
      left: 1rem;
      top: 0.6rem;
      display: none;
    }
    .svg-active {
      display: none;
      position: relative;
      z-index: 1;
      margin-left: -2px;
      margin-right: 4px;
    }
    .svg-default {
      display: inline;
      margin-right: 7px;
    }
    &:hover {
      font-weight: 700;
      background: #f1efef;
      color: #757575;
      span {
        display: block;
      }
      .svg-active {
        display: inline;
      }
      .svg-default {
        display: none;
      }
    }
  }
  input {
    border: 1px solid #817575;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0.25rem 0.5rem;
    border-radius: 0.32rem;
    outline: none;
    &::placeholder {
      color: #757575;
    }
  }
}

.zone-dnd-list {
  .zone-item-link {
    display: block;
    color: inherit;
    padding-bottom: 0.5rem;
    &.zone-item-link-disabled {
      pointer-events: none !important;
    }
  }
  .zone-item {
    background: #fff;
    border-radius: 10px;
    position: relative;
    padding: 0.55rem 2rem 0.55rem 1rem;
    height: 97px;
    overflow: hidden;
    color: inherit;
    cursor: pointer;
    transition-duration: 0s;

    h5 {
      margin-bottom: 0.45rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.9rem;
      font-weight: 400;
      transition-duration: 0s;
    }

    .zone-item-detail {
      font-size: 0.9rem;
      line-height: 1.4rem;
    }

    .zone-item-accordion {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3rem;
      display: none;
      button {
        position: absolute;
        top: 0.55rem;
        left: 50%;
        transform: translateX(-50%);
      }
      .plus {
        opacity: 0;
        pointer-events: none;
      }
      .line {
        position: absolute;
        top: 1.8rem;
        left: calc(50% - 1px);
        width: 2px;
        height: 100%;
        background: #fff;
        @include animation(opacity);
      }
    }

    .zone-item-actions {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 2rem;
      display: none;
      button {
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        opacity: 1;
        @include animation(right);
        &:hover {
          opacity: 0.5;
        }
        &:first-child {
          top: 0.55rem;
        }
        &:last-child {
          bottom: 0.55rem;
        }
      }
    }

    &.selected {
      background: rgba(#757575, 0.9);
      color: #fff;
      padding: 0.55rem 2rem 0.55rem 3rem;
      cursor: default;
      pointer-events: auto !important;
      @include animation(height);

      h5 {
        font-size: 1.25rem;
        font-weight: 700;
        @include animation(margin, font-size);
      }
      .zone-item-accordion {
        display: block;
      }
      &:hover,
      &.hover {
        background: rgba(#757575, 1);
        .zone-item-actions {
          display: block;
        }
      }
    }

    &.short {
      height: 2.5rem;
      padding-right: 3.5rem;
      h5 {
        font-size: 0.9rem;
        margin-top: 0.25rem;
      }
      .zone-item-accordion {
        .minus {
          opacity: 0;
          pointer-events: none;
        }
        .plus {
          opacity: 1;
          pointer-events: auto;
        }
        .line {
          opacity: 0;
        }
      }
      .zone-item-actions {
        button {
          &:last-child {
            right: 2.5rem;
          }
        }
      }
    }
  }
  &.dragging .zone-item .zone-item-actions button:first-child {
    opacity: 0.5 !important;
  }
  &:not(.dragging) {
    .zone-item:hover:not(.selected) {
      background: #f1efef;
      h5 {
        font-weight: 700;
      }
    }
  }
}

// .sidebar-header-tooltip {
//   margin-top: -8px;
// }
