$toast-background-color: #4385f5;

.Toastify__toast {
  padding: 1.2rem;
  font-family: inherit;
  background: $toast-background-color;
  border-radius: 0.4rem;
  min-height: auto;
  box-shadow: 0 3px 6px 0px rgba(mix(color-yiq($toast-background-color), $toast-background-color, 15%), .5);
  .Toastify__toast-body {
    margin: 0;
    font-size: 1rem;
  }
  .Toastify__close-button {
    position: absolute;
    top: 5px;
    right: 8px;
  }
}