$window-padding: 11px;

.master-page {
  min-height: 100vh;
  // background: #314360;
  background: url('../../assets/icons/Background.svg') ;;
  background-color: #41355c;
  // background-size: 100% 100%;
  display: flex;
  width: 100%;
  &.master-page-twin {
    flex-wrap: wrap;
    justify-content: center;
    > div {
      flex-basis: 0;
      flex-grow: 1;
      &:first-child {
        margin: 2.8rem 0 2.8rem 2.8rem;
        max-width: 680px;
        border-radius: 0.95rem;
        background: #f5f5f5;
        padding: 3.7rem;
        position: relative;
      }
      &:last-child {
        // background: url('../../assets/icons/MainImage.svg') center no-repeat;
        background-size: contain;
        margin: 2.4rem 1rem;
      }
    }
    .back-button {
      position: absolute;
      top: 1rem;
      left: 2rem;
      svg {
        > path {
          fill: #757575;
        }
      }
      @media (max-width: 575.98px) {
        position: static;
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }
  .window {
    margin: $window-padding;
    position: relative;
    width: 100%;
    height: calc(100vh - #{$window-padding * 2});
    background: #f1efef;
    overflow: hidden;
    border-radius: 0.9rem;
  }
}

.form-page {
  min-height: 100vh;
  // background: #314360;
  // background: url('../../assets/icons/Background.svg') ;;
  // background-color: #41355c;
  // background-size: 100% 100%;
  display: flex;
  width: 100%;
  &.master-page-twin {
    flex-wrap: wrap;
    justify-content: center;
    > div {
      flex-basis: 1;
      flex-grow: 0;
      &:first-child {
        margin: 2.8rem 0 2.8rem 2.8rem;
        max-width: 880px;
        border-radius: 0.95rem;
        background: #f5f5f5;
        padding: 3.7rem;
        position: relative;
      }
      &:last-child {
        // background: url('../../assets/icons/MainImage.svg') center no-repeat;
        background-size: contain;
        margin: 2.4rem 1rem;
      }
    }
    .back-button {
      position: absolute;
      top: 1rem;
      left: 2rem;
      svg {
        > path {
          fill: #757575;
        }
      }
      @media (max-width: 575.98px) {
        position: static;
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }
  .window {
    margin: $window-padding;
    position: relative;
    width: 100%;
    height: calc(100vh - #{$window-padding * 2});
    background: #f1efef;
    overflow: hidden;
    border-radius: 0.9rem;
  }
}

@media (max-width: 1199.98px) {
  .master-page {
    &.master-page-twin {
      > div {
        &:first-child {
          margin: 1rem;
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .master-page {
    &.master-page-twin {
      > div {
        &:first-child {
          margin: 0;
          border-radius: 0;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .master-page {
    &.master-page-twin {
      > div {
        &:first-child {
          padding: 1rem;
        }
      }
    }
  }
}
